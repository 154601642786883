import React, { useState, useEffect } from "react";
import { Input } from "components";
import { Button } from "components";


const CreateBuildingForm = ({makeTabActive}) => {
  const [contactName, setContactName] = useState("");
  const [contactPhoneNumber, setContactPhoneNumber] = useState("");
  const [contactEmailAddress, setContactEmailAddress] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [numberOfApartments, setNumberOfApartments] = useState("");
  const [bulkMeterNumber, setBulkMeterNumber] = useState("");
  const [bulkMeterAccountNumber, setBulkMeterAccountNumber] = useState("");
  const [error, setError] = useState('')

  const [isEditable, setIsEditable] = useState(true)

  const buildingInfo = {
   contactName, contactPhoneNumber, contactEmailAddress, address, city, province, postalCode, country, numberOfApartments, bulkMeterNumber, bulkMeterAccountNumber 
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (contactName === '' || contactPhoneNumber === '' || contactEmailAddress === '' || address === '' || city === '' || province === '' || postalCode === '' || country === '' || numberOfApartments === '' || bulkMeterNumber === '' || bulkMeterAccountNumber === ''){
      setError('All fields are required')
      return 
    }
    else{
      let buildingData = JSON.stringify(buildingInfo)
      localStorage.setItem('buildingInfo',buildingData)
      makeTabActive(3)
    }
  };
  
  const onHandleBlur = () => {
    localStorage.removeItem("addMetersFormData");
    localStorage.removeItem("tenantData");
    
  };

  const goBack = () => {
    makeTabActive(1)
  }

  useEffect(() => {
    const isChecked = JSON.parse(localStorage.getItem('isAddBuildingChecked'))
    const customerInfo = JSON.parse(localStorage.getItem('customerInfo'))
    if (isChecked) {
      setIsEditable(false)
      setAddress(customerInfo?.address)
      setCity(customerInfo?.city)
      setProvince(customerInfo?.province)
      setCountry(customerInfo?.country)
      setPostalCode(customerInfo?.postalCode)
    }
    
  }, [])

  // check if the user has already typed into the inputs before moving to the next page
    useEffect(() => {
      const building = JSON.parse(localStorage.getItem('buildingInfo'))
      if (building){
        setContactName(building?.contactName);
        setContactPhoneNumber(building?.contactPhoneNumber);
        setContactEmailAddress(building?.contactEmailAddress);
        setCity(building?.city);
        setProvince(building?.province);
        setPostalCode(building?.postalCode);
        setCountry(building?.country);
        setAddress(building?.address);
        setBulkMeterAccountNumber(building?.bulkMeterAccountNumber);
        setBulkMeterNumber(building?.bulkMeterNumber);
        setNumberOfApartments(building?.numberOfApartments)

      }
    }, [])
  

  return (
    <>
      <form 
      
      >
        <div className="mt-4">
          <div className="">
            <h3 className="building-header">Add Building</h3>
          </div>
        </div>
        <div className="customer-form-wrapper">
          <div>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Number of Apartments"
              type="number"
              labelText="Number of Apartments"
              value={numberOfApartments}
              onBlur={onHandleBlur}
              onChange={(e) => setNumberOfApartments(e.target.value)}
            ></Input>

            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="address"
              type="text"
              labelText="Address"
              disabled = {isEditable ? false : true}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Bulk Meter"
              type="text"
              labelText="Bulk Meter"
              value={bulkMeterNumber}
              onChange={(e) => setBulkMeterNumber(e.target.value)}
            ></Input>

            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Contact Name"
              labelText="Contact Name"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
            ></Input>

            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Bulk Meter Account"
              type="text"
              labelText="Bulk Meter Account"
              value={bulkMeterAccountNumber}
              onChange={(e) => setBulkMeterAccountNumber(e.target.value)}
            ></Input>

            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="City"
              labelText="City"
              value={city}
              disabled = {isEditable ? false : true}
              onChange={(e) => setCity(e.target.value)}
            ></Input>
          </div>
          <div>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Postal Code"
              labelText="Postal Code"
              value={postalCode}
              disabled = {isEditable ? false : true}
              onChange={(e) => setPostalCode(e.target.value)}
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Country"
              type="text"
              labelText="Country"
              disabled = {isEditable ? false : true}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            ></Input>

            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="State/Province"
              type="text"
              labelText="State/Province"
              value={province}
              disabled = {isEditable ? false : true}
              onChange={(e) => setProvince(e.target.value)}
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="email"
              labelText="Contact Email Address"
              value={contactEmailAddress}
              onChange={(e) => setContactEmailAddress(e.target.value)}
            ></Input>

            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="phonenumber"
              type="number"
              labelText="Contact Phone Number"
              value={contactPhoneNumber}
              onChange={(e) => setContactPhoneNumber(e.target.value)}
            ></Input>
          </div>
          <p className='text-[red] text-[12px]' >{error}</p>
        </div>
      </form>
          <div className="mt-4" >
          <Button onClick = {goBack} type='submit' className="border-[1px] mr-4 border-light_blue_800 bg-white cursor-pointer font-extrabold h-[64px] mb-[20px] px-[7px] sm:px-[15px] sm:py-[5px] rounded-[10px] text-[14px] text-center text-light_blue_800 w-[150px]">
                Back
              </Button>
          <Button onClick={handleSubmit} className="bg-light_blue_800 cursor-pointer font-extrabold h-[64px] mb-[20px] px-[7px] sm:px-[15px] sm:py-[5px] rounded-[10px] text-[14px] text-center text-white_A700 w-[150px]">
                
                  Next
              </Button>
              
          </div>
    </>
  );
};

export default CreateBuildingForm;
