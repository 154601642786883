import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { getImidServiceUrl } from "utils/api-util";
import closeIcon from "../../assets/images/imageIcon/img_close.svg";
import { ClipLoader } from "react-spinners";

const GenerateBillModal = ({
  isOpen,
  selectDate,
  onClose,
  onConfirm,
  openBillGenerateBillModalResponse,
  numberOfUnit,
  setIsDateSelected,
  buildings,
  setErrorMsg,
  errMsg,
  isDateSelected,
}) => {
  const navigate = useNavigate();

  const [billingInfo, setBillingInfo] = useState(null);
  const baseUrl = getImidServiceUrl();

  function formatDate(dateValue) {
    const date = new Date(dateValue);

    const month = date.getMonth() + 1; // Add 1 to make it human-readable
    const year = date.getFullYear();

    return `${year}-${month}`; // e.g., 7-2025
  }

  const billingDate = formatDate(billingInfo);

  const selectBillingDate = (date) => {
    selectDate(billingDate);
    setBillingInfo(date);
    setIsDateSelected(true);
  };

  const [loading, setLoading] = useState(false);
  const [err, setError] = useState(null);

  const { id } = useParams();
  if (!isOpen) return null;

  const transformArray = (array) => {
    return array.map(({ apartmentNumber }) => {
      // Retain 'name' and 'city', and add new properties
      return {
        apartmentNumber, // Original 'apartmentnumber
        buildingId: id, // New property
        commonAreaPercentage: 0, // Another new property
      };
    });
  };

  const apartmentBillRequests = transformArray(buildings);

  const data = {
    totalConsumption: 0,
    sendInvoice: false,
    billingDate,
    // tariff: 0,
    apartmentBillRequests,
  };

  const token = window.localStorage.getItem("token");

  const handleSubmit = async () => {
    setLoading(true);
    const url = `${baseUrl}/bills/generate/bulk`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      if (!isDateSelected) {
        setErrorMsg("Please select a date before you can proceed");
        return;
      }
      if (billingInfo === null) {
        setErrorMsg("Billing date is required");
        return;
      } else {
        setErrorMsg("");
        //   select date
        selectDate(billingDate);
      }
      // const res = await axios.post(url, data, { headers });
      const res = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
      });
      const fetchedData = await res.json();
      if (res.ok) {
        localStorage.setItem(
          "generateBillResponse",
          JSON.stringify(fetchedData)
        );
        // if successful, open response modal
        openBillGenerateBillModalResponse();
      } else {
        setError("Error sending Data");
      }
    } catch (err) {
      console.log(err);
      setError(err.message);
    } finally {
      setLoading(false); // Reset loading to false when login action is completed
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-0 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
        <div
          className="mb-2 cursor-pointer justify-end flex items-center"
          onClick={onClose}
        >
          <img className=" mr-1" src={closeIcon} alt="close" />
        </div>
        <h2 className="text-xl font-extrabold mb-4 text-center text-[#4F4F4F]">
          Generate bill for the building number: {numberOfUnit}
        </h2>

        <p>Select Billing Date</p>
        <input
          onChange={(e) => selectBillingDate(e.target.value)}
          type="date"
          className="w-full my-3"
        />

        <div className="flex justify-center space-x-4">
          {loading ? (
            <button className="px-4 py-2 bg-[#fff] text-[12px] w-[60%] border border-[#0074bb] text-[#0074bb] rounded hover:bg-[#dad5d54e]">
              <ClipLoader size={20} color="#0074bb" />
            </button>
          ) : (
            <button
              className="px-4 py-2 bg-[#fff] text-[12px] border border-[#0074bb] text-[#0074bb] rounded hover:bg-[#dad5d54e]"
              onClick={handleSubmit}
            >
              Generate bill without common area
            </button>
          )}

          <button
            className="px-4 py-2 bg-[#0074bb] text-[12px] text-[#fff] rounded hover:bg-[#0054bb]"
            onClick={onConfirm}
          >
            Generate bill with common area
          </button>
        </div>
        <p className="text-red-500 text-[10px] my-3 ">{errMsg}</p>
      </div>
    </div>
  );
};

GenerateBillModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default GenerateBillModal;
