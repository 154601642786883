import React, { useState, useEffect } from 'react'
import { getAuthUrl } from 'utils/api-util';
import next from "../../assets/images/imageIcon/next.svg";
import prev from "../../assets/images/imageIcon/prev.svg";

const IMIDDataTable = ({ reload, setReload }) => {


    const baseUrl = getAuthUrl();
    const [page, setPage] = useState(0); // Pagination state
    const [data, setData] = useState([]);
    const [apiResponse, setResponse] = useState(null)
    const [loading, setLoading] = useState(true);
    const [url, setUrl] = useState(`${baseUrl}/device/status?size=10&type=IMID`)




    // const url =
    //     ${baseUrl}/device/status?size=3&type=Imid&cursor=${apiResponse?.cursor};
    // Fetch customer data from api
    useEffect(() => {

        const headers = {
            "Content-Type": "application/json",
            // Authorization: Bearer ${token},
        };
        setReload(false)
        setLoading(true); // Start loading
        fetch(url, { headers })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {

                if (Array.isArray(data.items)) {
                    setResponse(data)

                    setData(data.items);

                } else {
                    setData([]);
                }
            })
            .catch((error) => {
                console.error("There was an error fetching the Customers!", error);
            })
            .finally(() => {
                setLoading(false); // Stop loading
            });
    }, [page, url, reload, setReload]); // Fetch data when token or page changes




    let content = null
    if (loading) {
        content = <div><p>Please wait</p></div>
    }
    else if (reload) {
        content = <div><p>Please wait</p></div>
    }
    else if (data.length > 0) {
        content = data.map((item, index) => {
            const globalIndex = page * 10 + index + 1;

            return (
                <tr key={item.id} className='h-12' >
                    <td className=' w-[5%] text-sm text-center ' >{globalIndex}</td>
                    <td className=' w-[20%]  text-sm' >{item?.meterId}</td>
                    <td className=' w-[20%]  text-sm' >{item?.imidId}</td>
                    <td className={`${item?.status === 'ONLINE' ? 'text-[green]' : 'text-[red]'} w-[20%]  text-sm`} >{item?.status}</td>
                    <td className=' w-[20%]  text-sm whitespace-nowrap' >{formatReadableDate(item.lastSeen)}</td>
                </tr>
            )
        })
    }



    // Handle Pagination
    const handleNextPage = () => {
        setUrl(`${baseUrl}/device/status?size=3&type=IMID&cursor=${apiResponse?.cursor}`)
        setPage((prevPage) => prevPage + 1);
    };

    const handlePreviousPage = () => {
        if (page > 0) {
            setPage((prevPage) => prevPage - 1);
        }
    };

    console.log(typeof (apiResponse?.cursor))


    function formatReadableDate(isoDate) {
        if (!isoDate) {
            return null;
        }

        return new Date(isoDate).toLocaleString("en-US", {
            year: "numeric",  // e.g., 2024
            month: "long",    // e.g., November
            day: "numeric",   // e.g., 4
            hour: 'numeric',
            minute: 'numeric'
        });
    }

    return (
        <div className='  h-fit w-full' >
            <table className=' w-full   my-6'>
                <thead>
                    <tr>
                        <th className='text-[13px] bg-[#0074bb] text-white text-center  ' >S/N</th>
                        <th className='text-[13px] bg-[#0074bb] text-white   ' >Meter ID</th>
                        <th className='text-[13px] bg-[#0074bb] text-white   ' >Imid Data</th>
                        <th className='text-[13px] bg-[#0074bb] text-white   ' >Status</th>
                        <th className='text-[13px] bg-[#0074bb] text-white   ' >Last Seen</th>
                    </tr>
                </thead>
                <tbody  >
                    {
                        content
                    }
                </tbody>
            </table>
            {typeof (apiResponse?.cursor) === 'string' &&
                <div className="flex flex-row mt-2 justify-end">
                    <img
                        className="arrow-tag"
                        src={prev}
                        alt="previous"
                        onClick={handlePreviousPage}
                        disabled={page === 0}
                    />
                    <div className="p-5">{page + 1}</div>
                    <img
                        className="arrow-tag"
                        src={next}
                        alt="next"
                        onClick={handleNextPage}
                    />
                </div>}
            {
                data.length === 0 &&
                <div className=' flex justify-center items-center w-full h-[300px]' >
                    <p className='w-[50%] font-bold text-[#BDBDBD] text-center' >No Data</p>
                </div>
            }

        </div>
    )
}

export default IMIDDataTable