
const env = process.env.REACT_APP_ENVIRONMENT;
console.log("=====Environment===== >>>" + env);

const authUrl = (env === "prod") ? process.env.REACT_APP_AUTH_SERVICE_BASE_URL : process.env.REACT_APP_AUTH_SERVICE_BASE_URL_TEST;
const imidServiceUrl = (env === "prod") ? process.env.REACT_APP_IMID_SERVICE_BASE_URL : process.env.REACT_APP_IMID_SERVICE_BASE_URL_TEST;
const imidDataUrl = (env === "prod") ? process.env.REACT_APP_IMID_DATA_SERVICE_BASE_URL : process.env.REACT_APP_IMID_DATA_SERVICE_BASE_URL_TEST;
const imidDataAPIKey = (env === "prod") ? process.env.REACT_APP_IMID_DATA_SERVICE_API_KEY : process.env.REACT_APP_IMID_DATA_SERVICE_API_KEY_TEST;

console.log(authUrl)

export const getAuthUrl = () => {
  return authUrl;
};

export const getImidServiceUrl = () => {
  return imidServiceUrl;
};

export const getImidDataUrl = () => {
  return imidServiceUrl;
};

export const getImidDataAPIKey = () => {
  return imidDataAPIKey;
};
