import React from "react";

import { Text, SelectBox, Img } from "components";
import DownArrow from "../../assets/images/imageIcon/img_arrowdown.svg"
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { BarChart } from '@mui/x-charts/BarChart';


const DashbaordTwoAnalysticcard = (props) => {
  console.log(`Rendering DashbaordTwoAnalysticcard with props: ${props.data}`);
  console.log(props)

  const barData = props?.data;
  const ylData = barData?.data && barData.data.map(e => e.amount);
  const yrData = barData?.data && barData.data.map(e => e.Energy);
  const xLabels = barData?.data && barData.data.map(e => e.timeStamp);

  const totalAmount = barData?.amount && Number(barData?.amount);
  const totalEnergy = barData?.energyUsed && Number(barData?.energyUsed);

  console.log(`ylData: ${JSON.stringify(ylData)}`);
  console.log(`yrData: ${JSON.stringify(yrData)}`);
  console.log(`totalAmount: ${totalAmount}`);
  console.log(`totalEnergy: ${totalEnergy}`);

  return (
      <div className={props.className}>
        <div className="absolute bg-white_A700 border-[1px] border-gray_400 border-solid flex h-[100%]   justify-center m-[auto] p-[16px] rounded-[10px] w-[100%]">
          <div className="flex flex-col items-start justify-start mb-[5px] mt-[8px] md:w-[100%] w-[100%]">
            <div className="flex md:flex-col flex-row md:gap-[20px] items-start justify-start md:w-[100%] w-[100%]">
              <div className="flex items-center justify-start md:ml-[0] ml-[14px] md:w-[100%] w-[100%]">
                <div className="flex flex-col items-start justify-start self-stretch w-[100%]">
                  <Text
                    className="font-medium font-montserrat text-left text-light_blue_800 w-[auto]"
                    variant="body2"
                  >
                    {props?.parameter}
                  </Text>
                  <Text
                    className="font-montserrat font-normal not-italic text-gray_800 text-left w-[auto]"
                    variant="body5"
                  >
                    Device: {props?.deviceId}
                  </Text>
                </div>
              </div>
              <SelectBox
                className="border-[1px] border-light_blue_800 border-solid font-lato font-medium md:ml-[0] ml-[18px] px-[15px] py-[8px] rounded-[5px] text-[12px] text-left text-light_blue_800 md:w-[100%] w-[100%]"
                placeholderClassName="text-light_blue_800"
                name="ParameterSelection"
                placeholder="Parameter"
                isSearchable={false}
                isMulti={false}
                indicator={
                  <Img
                    src={DownArrow}
                    className="h-[16px] w-[16px]"
                    alt="arrow_down"
                  />
                }
                options={props.parameters}
                value={props.selectedParameter}
                onChange={props.onChange}
              ></SelectBox>
              <SelectBox
                className="border-[1px] border-light_blue_800 border-solid font-lato font-medium md:ml-[0]  px-[15px] py-[8px] rounded-[5px] text-[12px] text-left text-light_blue_800 md:w-[100%] w-[100%]"
                placeholderClassName="text-light_blue_800"
                name="Interval"
                placeholder="Interval"
                isSearchable={false}
                isMulti={false}
                indicator={
                  <Img
                    src={DownArrow}
                    className="h-[16px] w-[16px]"
                    alt="arrow_down"
                  />
                }
                selectedParameter={props.selectedInterval}
                options={props.intervals}
                value={props.selectedInterval}
                onChange={props.onIntervalChange}
              ></SelectBox>      
               {/* <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker label="Basic date picker" className="border-[1px] border-light_blue_800 border-solid font-lato font-medium md:ml-[0]  px-[15px] py-[8px] rounded-[5px] text-[12px] text-left text-light_blue_800 md:w-[100%] w-[100%]"/>
              </LocalizationProvider> */}
            </div>
            {barData.data && (
            <div className="flex flex-col min-h-[80%] items-center justify-start mt-[1px] w-[100%]">
              <BarChart
                        // width={700}
                        // height={300}
                        series={[
                          {
                            data: ylData,
                            label: 'CAD',
                            id: 'pvId',
                            yAxisId: 'leftAxisId',
                            color:'#f4bd50'
                          },
                          {
                            data: yrData,
                            label: 'kWh',
                            id: 'uvId',
                            yAxisId: 'rightAxisId',
                            color: '#ed6a5e'
                          },
                        ]}
                        xAxis={[{ data: xLabels, scaleType: 'band' }]}
                        yAxis={[{ id: 'leftAxisId', label:'CAD' }, { id: 'rightAxisId', label:'kWh' }]}
                        rightAxis="rightAxisId"
                        sx={{
                          [`.${axisClasses.left} .${axisClasses.label}`]: {
                            transform: 'translateX(-10px)',
                            stroke: '#f4bd50'
                          },
                          [`.${axisClasses.right} .${axisClasses.label}`]: {
                            transform: 'translateX(12px)',
                            stroke: '#ed6a5e'
                          },
                          ["& .MuiChartsAxis-right"]:{
                            stroke: '#ed6a5e'
                          },
                          ["& .MuiChartsAxis-left"]: {
                            stroke: '#f4bd50'
                          }
                  }}
                />
                {totalAmount && (<div className="flex flex-col items-start justify-start self-stretch w-[100%] ml-8">
                  Total Amount: {totalAmount.toFixed(2)} CAD
                </div>)}
                {totalEnergy && (<div className="flex flex-col items-start justify-start self-stretch w-[100%] ml-8">
                  Total Energy: {totalEnergy.toFixed(2)} kWh
                </div>)}
              {/* <ResponsiveBar
                data={barData.data}
                // keys= {["Energy", "amount"]}
                keys={barData.keys}
                indexBy={barData.indexBy}
                margin={{ top: 50, right: 100, bottom: 50, left: 80 }}
                padding={0.2}
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                colors={{ scheme: "nivo" }}
                groupMode="grouped"
                defs={[
                  {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "#38bcb2",
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "#eed312",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 5,
                  },
                ]}
                // fill={[
                //   {
                //     match: {
                //       id: "fries",
                //     },
                //     id: "dots",
                //   },
                //   {
                //     match: {
                //       id: "sandwich",
                //     },
                //     id: "lines",
                //   },
                // ]}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: barData.yLegend,
                  legendPosition: "middle",
                  legendOffset: 32,
                  truncateTickAt: 0,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: barData.xLegend,
                  legendPosition: "middle",
                  legendOffset: -40,
                  truncateTickAt: 0,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                legends={[
                  {
                    dataFrom: "keys",
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              /> */}
              {" "}
            </div> )}
          </div>
        </div>
      </div>
  );
};

DashbaordTwoAnalysticcard.defaultProps = {
  deviceId: "000300006438",
  parameter: "Total Active Energy",
  scale: "every day",
};

export default DashbaordTwoAnalysticcard;
