import React, { useState } from 'react'
import { Button } from "components";
import MeteringDeviceTable from "components/DeviceInformation/MeteringDeviceTable";
import IMIDDataTable from "components/DeviceInformation/IMIDDataTable";


const IMIDDataPage = () => {

  const [reload, setReload] = useState(false)

  const [apiType, setApiType] = useState('Imid')
  const refreshData = () => {
    setReload(true)
  }

  const tabs = [
    { title: 'IMID Data', content: <IMIDDataTable reload={reload} setReload={setReload} /> },
    { title: 'Metering Devices', content: <MeteringDeviceTable reload={reload} setReload={setReload} /> },
  ];

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
    if (index === 1) {
      setApiType('meter')
    }
    else if (index < 1) {
      setApiType('Imid')
    }
  };


  return (
    <>
      <div className="bg-gray_50 font-montserrat h-[100%] mx-[auto] pb-[82px] relative w-[100%]">
        <div className="flex h-[100%] flex-col my-[auto] md:px-[20px] w-full">
          <div className='flex mt-2 items-center justify-end w-full' >
            <Button onClick={() => refreshData()} className="bg-light_blue_800 cursor-pointer font-extrabold h-[64px] px-[20px] py-[10px] rounded-[10px] text-[14px] text-center text-white_A700 w-[150px]">
              Refresh Data
            </Button>
          </div>

          {/*<div className="flex sm:flex-col flex-row md:gap-[40px] items-start justify-between mt-[43px] w-[100%]">
            
          </div>*/}

          <div className="tab-container">
            {/* <h1>Tab Example</h1> */}
            <div className="tabs">
              {tabs.map((tab, index) => (
                <div
                  key={index}
                  className={`tab ${index === activeTab ? 'active' : ''}`}
                  onClick={() => handleTabClick(index)}
                >
                  {tab.title}
                  {index === activeTab && <hr style={{ backgroundColor: '#0074BB', height: '2px', border: 'none', marginTop: "9px" }} />}
                </div>
              ))}
            </div>
            <div className="tab-content">
              {tabs[activeTab].content}
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default IMIDDataPage;