import React, { useState } from "react";
import PropTypes from "prop-types";
import closeIcon from "../../assets/images/imageIcon/img_close.svg";
import { Button, Input } from "components";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { getImidServiceUrl } from "utils/api-util";
import { ClipLoader } from "react-spinners";

const AllocateCommonArea = ({
  isOpen,
  openModal,
  billingDate,
  buildings,
  errMsg,
  setErrorMsg,
  openBillGenerateBillModalResponse,
  onClose,
}) => {
  const baseUrl = getImidServiceUrl();
  const navigate = useNavigate();

  const [totalConsumption, setTotalConsumption] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const [loading, setLoading] = useState(false);
  const [err, setError] = useState(null);

  let content = null;

  const [inputValues, setInputValues] = useState(
    Array(buildings.length).fill("")
  );

  const { id } = useParams();

  // clear all values in input
  const closePopup = () => {
    setTotalConsumption("");
    onClose();
    setInputValues(Array(buildings.length).fill(""));
    setIsChecked(false);
  };

  // transfor the buildings array and return a new array that contains the required payload array
  const transformArray = (array) => {
    return array.map(({ apartmentNumber }, index) => {
      const commonAreaValue = inputValues[index % inputValues.length];
      return {
        apartmentNumber, // Original 'apartmentNumber'
        buildingId: id, // New property
        commonAreaPercentage: commonAreaValue, // Value based on the index
      };
    });
  };

  const apartmentBillRequests = transformArray(buildings);

  const data = {
    totalConsumption,
    sendInvoice: isChecked,
    billingDate,
    // tariff: 0,
    apartmentBillRequests,
  };

  const token = window.localStorage.getItem("token");

  const handleSubmit = async () => {
    setLoading(true);
    const url = `${baseUrl}/bills/generate/bulk`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      // const res = await axios.post(url, data, { headers });
      const res = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
      });
      const fetchedData = await res.json();

      if (res.ok) {
        console.log(fetchedData);
        localStorage.setItem(
          "generateBillResponse",
          JSON.stringify(fetchedData)
        );
        // if successful, open response modal
        openBillGenerateBillModalResponse();
        closePopup();
      } else {
        setError("Error sending Data");
      }
    } catch (err) {
      console.log(err);
      setError(err.message);
    } finally {
      setLoading(false); // Reset loading to false when login action is completed
    }
  };

  // Handler to update the state as the user types
  const handleInputChange = (e, id) => {
    const { value } = e.target;
    const newValues = [...inputValues];
    newValues[id] = value;
    setInputValues(newValues);
  };

  // Function to submit or log the input values
  const handleInput = () => {
    const total = inputValues.reduce((sum, value) => sum + Number(value), 0);

    if (total !== 100) {
      // console.log(total)
      setErrorMsg("All values must be equal to 100");
    } else {
      // If the values equals to 100, excute function
      setErrorMsg("");
      handleSubmit();
      // console.log(total)
    }
  };

  if (buildings?.length > 3) {
    content = (
      <div className="grid gap-2 grid-cols-2">
        {buildings?.map((_, id) => {
          return (
            <Input
              key={id}
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name={`input-${id}`}
              value={inputValues[id]} // Controlled input
              onChange={(e) => handleInputChange(e, id)}
              labelText={`Unit ${id + 1}`}
              type="number"
              placeholder="--"
            ></Input>
          );
        })}
      </div>
    );
  } else if (buildings.length < 3) {
    content = buildings?.map((building, id) => {
      return (
        <Input
          key={id}
          wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[10px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
          className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
          labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
          name={`input-${id}`}
          value={inputValues[id]} // Controlled input
          onChange={(e) => handleInputChange(e, id)}
          labelText={`Unit ${id + 1}`}
          type="number"
          placeholder="--"
        ></Input>
      );
    });
  }

  if (!isOpen) return null;

  return (
    <div className="fixed scroll-y flex items-center w-full h-full justify-center z-50 top-[50px] left-0 bg-black bg-opacity-50">
      <div className=" ">
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
          <div
            className="mb-2 cursor-pointer justify-end flex items-center"
            onClick={closePopup}
          >
            <img className=" mr-1" src={closeIcon} alt="close" />
            <p>close</p>
          </div>
          <Input
            wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
            className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
            labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
            name="user-name"
            value={totalConsumption}
            onChange={(e) => setTotalConsumption(e.target.value)}
            labelText="Total Monthly numeric bill"
            type="number"
            placeholder="0"
          ></Input>
          <p className="my-2">Enter percentage for common area of each unit</p>
          <div>{content}</div>

          <div className="mt-2 flex ">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
            <p className="ml-2">Send Invoice</p>
          </div>

          {loading ? (
            <Button className="bg-light_blue_800 flex justify-center items-center cursor-pointer font-extrabold h-[64px] px-[7px] sm:px-[15px] sm:py-[5px] py-[10px] rounded-[10px] text-[14px] text-center text-white_A700 mt-4 w-full">
              <ClipLoader size={20} color="#ffffff" />
            </Button>
          ) : (
            <Button
              onClick={handleInput}
              className="bg-light_blue_800 flex justify-center items-center cursor-pointer font-extrabold h-[64px] px-[7px] sm:px-[15px] sm:py-[5px] py-[10px] rounded-[10px] text-[14px] text-center text-white_A700 mt-4 w-full"
            >
              Generate Bill
            </Button>
          )}
          <p className="text-red-500 text-[10px] my-3 ">{errMsg}</p>
        </div>
      </div>
    </div>
  );
};

AllocateCommonArea.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AllocateCommonArea;
