import React, { useEffect, useState } from "react";
import { Button, Img, List, Text } from "components";
import group from "../../assets/images/imageIcon/img_user_light_blue_800_40x40.svg";
import "./index.css";
import { useNavigate } from "react-router-dom";
import next from "../../assets/images/imageIcon/next.svg";
import prev from "../../assets/images/imageIcon/prev.svg";
import { getImidServiceUrl } from "utils/api-util";

const ImidSuperAdminDashboard = () => {
  const baseUrl = getImidServiceUrl();

  const url = `${baseUrl}/customer`;
  const [page, setPage] = useState(0); // Pagination state
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const viewBuildings = (data) => {
    navigate(`/buildings`, { state: data });
  };

  const token = window.localStorage.getItem("token");

  // Fetch customer data from api
  useEffect(() => {
    const params = new URLSearchParams({
      pageSize: 12,
      page: page, // Use pagination state here
    }).toString();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    setLoading(true); // Start loading
    fetch(`${url}?${params}`, { headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data.items)) {
          setData(data.items);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the Customers!", error);
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  }, [token, page, url]); // Fetch data when token or page changes

  // Handle Pagination
  const handleNextPage = () => {
    if (data.length === 12) {
      setPage((prevPage) => prevPage + 1);
    } else return;
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  let content = (
    <div className="w-full justify-center flex items-center">
      <p>Please wait...</p>
    </div>
  );

  if (data.length > 0) {
    content = (
      <div>
        <List
          className="sm:flex-col flex-row font-montserrat gap-[38px] grid sm:grid-cols-1 mt-4 md:grid-cols-3 grid-cols-4 justify-center ml-[auto] md:mr-[59px] md:mt-[50px]  w-full"
          orientation="horizontal"
        >
          {data?.map((props, index) => {
            return (
              <React.Fragment key={`IMIDCustomerTwoDashboardcard${index}`}>
                <div
                  onClick={() => viewBuildings(props)}
                  className="flex flex-col items-start cursor-pointer justify-start self-stretch sm:w-[100%] w-[auto]"
                >
                  <div className="bg-white_A700 border-[1px] border-gray_51 border-solid flex items-start justify-start p-[18px] sm:px-[20px] rounded-[10px] w-[100%]">
                    <div className="flex flex-col gap-[20px] items-start justify-center mb-[5px] mt-[10px] self-stretch w-[auto]">
                      <Button className="bg-gray_51 border-[1px] border-gray_51 border-solid flex h-[40px] items-center justify-center p-[10px] rounded-[50%] w-[40px]">
                        <Img src={group} className="h-[20px]" alt="user" />
                      </Button>
                      <div className="flex flex-col gap-[5px] items-start justify-center self-stretch w-[auto]">
                        <Text
                          className="font-montserrat font-normal not-italic text-gray_601 text-left w-auto"
                          variant="body4"
                        >
                          {truncateString(props?.name, 10)}
                        </Text>
                        <Text
                          className="font-montserrat font-normal not-italic text-gray_601 text-left w-auto"
                          variant="body4"
                        >
                          No. of buildings :
                          {props?.numberOfBuildings
                            ? props?.numberOfBuildings
                            : "  -"}
                        </Text>
                        <div className="flex flex-row">
                          <Text
                            className="font-montserrat font-normal not-italic text-gray_601 text-left w-auto"
                            variant="body4"
                          >
                            Account Status :
                          </Text>
                          <Text
                            className={`font-montserrat font-normal not-italic ${
                              props?.status === "ENABLED"
                                ? "text-[green]"
                                : "text-gray_601"
                            } text-left w-auto`}
                            variant="body4"
                          >
                            {props?.status}
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </List>
        <div className="flex flex-row mt-2 justify-end">
          <img
            className="arrow-tag"
            src={prev}
            alt="previous"
            onClick={handlePreviousPage}
            disabled={page === 0}
          />
          <div className="p-5">{page + 1}</div>
          <img
            className="arrow-tag"
            src={next}
            alt="next"
            onClick={handleNextPage}
          />
        </div>
      </div>
    );
  } else if (data.length === 0 && !loading) {
    content = (
      <div className="w-full justify-center flex items-center">
        <p> No Customers available</p>
      </div>
    );
  }

  // shorten the length of strings in the text
  function truncateString(str, maxLength) {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    }
    return str;
  }

  return (
    <div className=" w-full mt-5">
      <div className="flex justify-end">
        <Button
          onClick={() => navigate("/imidcustomer/new-customer")}
          className="bg-light_blue_800 addCustomerBtn cursor-pointer font-extrabold h-[64px] mb-[20px] px-[7px] sm:px-[15px] sm:py-[5px] py-[10px] rounded-[10px] text-[14px] text-center text-white_A700 w-[150px]"
        >
          Add Customer
        </Button>
      </div>

      {content}
    </div>
  );
};

export default ImidSuperAdminDashboard;
