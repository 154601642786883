import React, { useState, useEffect } from 'react'
// import { CSVLink } from 'react-csv'
import { useNavigate } from 'react-router-dom'
// import BillingDoc fro../../pages/BillingDocDoc'
import { useSelector } from 'react-redux'
import { Button } from 'components'
import { getImidServiceUrl } from 'utils/api-util'


const BillingHistory = (param) => {

    const navigate = useNavigate()

    const baseUrl = getImidServiceUrl();
    const user = useSelector((state) => state.auth.userData);

    const params = param?.tenantId === undefined ? '' : `&tenantId=${param.tenantId}`;
    const accountId = param?.accountId === undefined ? user?.accountNumber : param.accountId
    const url = `${baseUrl}/bills?pageSize=10${params}`
    console.log("accountId: " + accountId);

    console.log("Billing data url: " + url);
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);



    const token = window.localStorage.getItem("token")


    const formatDate = (date) => {
        const d = new Date(date);
        return d.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    }
    const fetchData = async () => {
        try {
            const headers = {
                // Add any headers you need here
                'Content-Type': 'application/json',
                // Example header:
                Authorization: `Bearer ${token}`,
            };
            const response = await fetch(url, { headers });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setData(jsonData?.items);
            console.log(jsonData, 'JSON DATA')
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []); // Empty array as dependency means this effect runs only once, similar to componentDidMount



    const viewBill = (data) => {
        console.log("account number: ", user.accountNumber)
        data.accountNumber = user.accountNumber;
        navigate(`/imidtenant/bill/${data.id}`, { state: data })
    }

    let content = null
    if (data?.length === 0) {
        return content
    }
    else if (data?.length > 0) {
        content = data?.map((data, index) => {
            return (
                <tr key={data?.id} >
                    <td>{index + 1}</    td>
                    <td>{formatDate(data?.billingDate)}</td>
                    <td>{accountId}</td>
                    <td>{data?.totalConsumption}</td>
                    <td>{data?.totalBillAfterTax}</td>
                    <td>{data?.creditAmount}</td>
                    <td className='pl-0'>
                        <div onClick={() => viewBill(data)} className="header-button h-fit p-2 my-2 mx-auto w-fit ml-auto rounded-md">
                            <Button >
                                View Bill
                            </Button>
                        </div>
                    </td>

                </tr>
            )
        })
    }


    return (
        <div className='h-[50vh] relative overflow-hidden border w-full p-3 mb-2 bg-white' >
            <div className='flex justify-between billingHistoryContainer w-full items-center mb-6 ' >
                <h1 className='font-bold mb-2 md:mb-0 ' >Billing History</h1>
                {/*<div className='flex items-center' >
                    <input className='rounded-md' type='date' />
                    <CSVLink filename='Billing History' data={data} onClick={() => { }} >
                        <div className='flex h-11 px-2 cursor-pointer rounded-md ml-2 items-center bg-[#0074bb]' >
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.94403 14.4233L7.94403 14.4233L7.94116 14.4221C7.92605 14.4162 7.9099 14.4043 7.89651 14.384C7.8828 14.3632 7.875 14.3379 7.875 14.3125V9.3125C7.875 9.28235 7.88728 9.25107 7.91293 9.22543C7.93857 9.19978 7.96985 9.1875 8 9.1875C8.03015 9.1875 8.06143 9.19978 8.08707 9.22543C8.11272 9.25107 8.125 9.28235 8.125 9.3125V12.8042V14.0113L8.97855 13.1577L9.57855 12.5577C9.60008 12.5362 9.63082 12.5229 9.66667 12.5229C9.70252 12.5229 9.73325 12.5362 9.75478 12.5577C9.77631 12.5792 9.78958 12.61 9.78958 12.6458C9.78958 12.6817 9.77631 12.7124 9.75478 12.7339L8.08811 14.4006C8.06663 14.4221 8.02774 14.4375 8 14.4375H7.99998C7.99144 14.4375 7.97962 14.4375 7.94403 14.4233Z" fill="white" stroke="white" />
                                <path d="M8.00003 14.4375C7.98235 14.4375 7.96683 14.4343 7.9535 14.4289C7.94065 14.4238 7.9266 14.4153 7.91192 14.4006L6.24525 12.734C6.22373 12.7125 6.21045 12.6817 6.21045 12.6459C6.21045 12.61 6.22373 12.5793 6.24525 12.5578C6.26678 12.5362 6.29751 12.5229 6.33337 12.5229C6.36922 12.5229 6.39995 12.5362 6.42148 12.5578L8.08815 14.2244C8.10967 14.2459 8.12295 14.2767 8.12295 14.3125C8.12295 14.3484 8.10967 14.3791 8.08815 14.4006C8.07346 14.4153 8.05942 14.4238 8.04656 14.4289C8.03324 14.4343 8.01772 14.4375 8.00003 14.4375Z" fill="white" stroke="white" />
                                <path d="M12.9998 18.6042H7.99984C5.79473 18.6042 4.34018 18.1312 3.42735 17.2183C2.51452 16.3055 2.0415 14.8509 2.0415 12.6458V7.64583C2.0415 5.44073 2.51452 3.98617 3.42735 3.07335C4.34018 2.16052 5.79473 1.6875 7.99984 1.6875H12.1665C12.1967 1.6875 12.2279 1.69978 12.2536 1.72543C12.2792 1.75107 12.2915 1.78235 12.2915 1.8125C12.2915 1.84265 12.2792 1.87393 12.2536 1.89957C12.2279 1.92522 12.1967 1.9375 12.1665 1.9375H7.99984C6.04373 1.9375 4.56874 2.28024 3.60149 3.24749C2.63424 4.21474 2.2915 5.68972 2.2915 7.64583V12.6458C2.2915 14.6019 2.63424 16.0769 3.60149 17.0442C4.56874 18.0114 6.04373 18.3542 7.99984 18.3542H12.9998C14.9559 18.3542 16.4309 18.0114 17.3982 17.0442C18.3654 16.0769 18.7082 14.6019 18.7082 12.6458V8.47917C18.7082 8.44902 18.7205 8.41774 18.7461 8.3921C18.7717 8.36645 18.803 8.35417 18.8332 8.35417C18.8633 8.35417 18.8946 8.36645 18.9202 8.3921C18.9459 8.41774 18.9582 8.44902 18.9582 8.47917V12.6458C18.9582 14.8509 18.4852 16.3055 17.5723 17.2183C16.6595 18.1312 15.2049 18.6042 12.9998 18.6042Z" fill="white" stroke="white" />
                                <path d="M18.8332 9.1044H15.4998C12.6498 9.1044 11.5415 7.99607 11.5415 5.14607V1.81273C11.5415 1.56273 11.6915 1.3294 11.9248 1.23773C12.1582 1.13773 12.4248 1.19607 12.6082 1.37107L19.2748 8.03773C19.4498 8.21273 19.5082 8.48773 19.4082 8.72107C19.3082 8.9544 19.0832 9.1044 18.8332 9.1044ZM12.7915 3.32107V5.14607C12.7915 7.29607 13.3498 7.8544 15.4998 7.8544H17.3248L12.7915 3.32107Z" fill="white" />
                            </svg>

                            <button className='text-white' >Download as PDF</button>
                        </div>
                    </CSVLink>
                </div>*/}
            </div>
            <table id="customers">
                <tr  >
                    <th className='bg-[#0074bb] text-white'>S/N</th>
                    <th className='bg-[#0074bb] text-white'>Billing Date</th>
                    <th className='bg-[#0074bb] text-white'>Account Number</th>
                    <th className='bg-[#0074bb] text-white'>Total KWT</th>
                    <th className='bg-[#0074bb] text-white'>Billing Amount</th>
                    <th className='bg-[#0074bb] text-white'>Current Balance</th>
                    <th className='bg-[#0074bb] text-white'>Action</th>
                </tr>

                {
                    content
                }

            </table>





        </div>
    )
}

export default BillingHistory