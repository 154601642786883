
const offset = new Date().getTimezoneOffset();
const offsetValue = Math.floor(offset / 60);

console.log("offset: " + offsetValue);
console.log("now: " + new Date())

const getRange = (range) => {
  if (range === "dayTotal") {
    return getDayTotalRange();
  }else if (range === "lastHour") {
    return getLastHourRange();
  } else if (range === "today") {
    return getTodayRange();
  } else if (range === "yesterday") {
    return getYesterdayRange();
  } else if (range === "thisWeek") {
    return getWeekRange();
  } else if (range === "lastWeek") {
    return getLastWeekRange();
  } else if (range === "2Weeks") {
    return getLastTwoWeekRange();
  } else if (range === "thisMonth") {
    return getThisMonthRange();
  } else if (range === "lastMonth") {
    return getLastMonthRange();
  } else if (range === "lastMonthBilling") {
    return getLastBillingRange();
  }
  return getTodayRange();
};

const getChartType = (range) => {
  if (range === "dayTotal") {
    return "hourly";
  } else if (range === "lastHour") {
    return "hourly";
  } else if (range === "today") {
    return "hourly";
  } else if (range === "yesterday") {
    return "hourly";
  } else if (range === "thisWeek") {
    return "daily";
  } else if (range === "lastWeek") {
    return "daily";
  } else if (range === "2Weeks") {
    return "daily";
  } else if (range === "thisMonth") {
    return "daily";
  } else if (range === "lastMonth") {
    return "daily";
  }
}

const getDayTotalRange = () => {
  let startDate = new Date();
  let endDate = new Date();

  startDate.setSeconds(0);
  startDate.setMilliseconds(0);

  endDate.setSeconds(0);
  endDate.setMilliseconds(0);
  endDate.setHours(endDate.getHours + 1);

  // startDate.toLocaleDateString();

  return [formatDate(startDate), formatDate(endDate)];
}

const getLastHourRange = () => {
  let startDate = new Date();
  let endDate = new Date();
  // console.log(startDate.toISOString())
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);
  startDate.setHours(startDate.getHours() - 1);

  endDate.setSeconds(0);
  endDate.setMilliseconds(0);
  // endDate.setHours(startDate.getHours() - 1);

  startDate.toLocaleDateString();

  return [formatDate(startDate), formatDate(endDate)];
};

const getTodayRange = () => {
  let startDate = new Date();
  let endDate = new Date();
  // console.log(startDate.toISOString())
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);
  startDate.setMinutes(0);
  startDate.setHours(0);

  return [formatDate(startDate), formatDate(endDate)];
};

const getYesterdayRange = () => {
  let startDate = new Date();
  let endDate = new Date();
  console.log(startDate.toISOString());
  startDate.setDate(startDate.getDate() - 1);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);
  startDate.setMinutes(0);
  startDate.setHours(0);

  // endDate.setDate(endDate.getDate() - 1);
  endDate.setSeconds(0);
  endDate.setMilliseconds(0);
  endDate.setMinutes(1);
  endDate.setHours(0);

  return [formatDate(startDate), formatDate(endDate)];
};

const getWeekRange = () => {
  let today = new Date();
  // console.log(today.toISOString())

  const firstDay = new Date(today.setDate(today.getDate() - today.getDay()));
  firstDay.setHours(0);
  firstDay.setMinutes(0);
  firstDay.setSeconds(0);
  firstDay.setMilliseconds(0);
  firstDay.setDate(firstDay.getDate() - 1); //go back another week


  return [formatDate(firstDay), formatDate(new Date())];
};

const getLastWeekRange = () => {
  //get the first and last day of the previous week
  let today = new Date();
  const firstDayOfThisWeek = new Date(today.setDate(today.getDate() - today.getDay()));
  firstDayOfThisWeek.setHours(0);
  firstDayOfThisWeek.setMinutes(0);
  firstDayOfThisWeek.setSeconds(0);
  firstDayOfThisWeek.setMilliseconds(0);
  const firstDayOfLastWeek = new Date(firstDayOfThisWeek.setDate(firstDayOfThisWeek.getDate() - 7));
  firstDayOfLastWeek.setDate(firstDayOfLastWeek.getDate() - 1); //go back another week
  const lastDayOfLastWeek = new Date(firstDayOfThisWeek.setDate(firstDayOfThisWeek.getDate() + 7));

  return [formatDate(firstDayOfLastWeek), formatDate(lastDayOfLastWeek)];
}

const getLastTwoWeekRange = () => {
  let today = new Date();

  // console.log(today.toISOString())
  const firstDay = new Date(today.setDate(today.getDate() - today.getDay()));
  firstDay.setHours(0);
  firstDay.setMinutes(0);
  firstDay.setSeconds(0);
  firstDay.setMilliseconds(0);
  firstDay.setDate(firstDay.getDate() - 8); //go back another week


  return [formatDate(firstDay), formatDate(new Date())];
};

const getThisMonthRange = () => {
  let today = new Date();

  // console.log(today.toISOString())
  const firstDay = new Date();
  firstDay.setDate(1); //go back another week
  firstDay.setHours(0);
  firstDay.setMinutes(0);
  firstDay.setSeconds(0);
  firstDay.setMilliseconds(0);
  firstDay.setDate(firstDay.getDate() - 1); 


  return [formatDate(firstDay), formatDate(today)];
};

const getBeginingOfMonthRange = () => {
  let today = new Date();

  // console.log(today.toISOString())
  const firstDay = new Date();
  firstDay.setDate(1); //go back another week
  firstDay.setHours(0);
  firstDay.setMinutes(0);
  firstDay.setSeconds(0);
  firstDay.setMilliseconds(0);

  today.setDate(1);
  today.setHours(0);
  today.setMinutes(20)
  firstDay.setMilliseconds(0);

  return [formatDate(firstDay), formatDate(today)];
};

const getLastMonthRange = () => {
  let lastDay = new Date();
  let firstDay = new Date();

  // console.log(lastDay.toISOString())
  firstDay.setDate(1); //go back another week
  firstDay.setHours(0);
  firstDay.setMinutes(0);
  firstDay.setSeconds(0);
  firstDay.setMilliseconds(0);
  firstDay.setMonth(firstDay.getMonth() - 1);
  firstDay.setDate(firstDay.getDate() - 1); 

  lastDay.setDate(1); //go back another week
  lastDay.setHours(23);
  lastDay.setMinutes(59);
  lastDay.setSeconds(59);
  lastDay.setMilliseconds(999);

  lastDay.setDate(lastDay.getDate() - 1);

  return [formatDate(firstDay), formatDate(lastDay)];
};

const getLastBillingRange = () => {
  let lastDay = new Date();
  let firstDay = new Date();

  // console.log(lastDay.toISOString())
  firstDay.setDate(1); //go back another week
  firstDay.setHours(0);
  firstDay.setMinutes(0);
  firstDay.setSeconds(0);
  firstDay.setMilliseconds(0);
  firstDay.setMonth(firstDay.getMonth() - 1);

  lastDay.setDate(1); //go back another week
  lastDay.setHours(0);
  lastDay.setMinutes(0);
  lastDay.setSeconds(0);
  lastDay.setMilliseconds(0);

  lastDay.setMonth(lastDay.getMonth() - 1);

  return [formatDate(firstDay), formatDate(new Date)];
};

const formatDate = (date) => {
  const formated = date.toISOString().substring(0,19);

  console.log("formatted time: " + formated);
  return formated;

};

const formatNumber = (number) => {
  if (number < 10) {
    return "0" + number;
  } else {
    return number;
  }
};

const extractHour = (date) => {
  
  const hour = date.getHours();
  // const hour = +hourString
  let num
  if (hour === 0) {
    num = '12 AM'
  } else if (hour < 12) {
    num = hour + ' AM';
  } else if (hour === 12) {
    num = '12 PM'
  } else if (hour > 12) {
    num = (hour - 12) + ' PM';
  }
  return num;
};

const getLocalDateFromUTC = (str) => {
  return new Date(str + ".000Z");
}

export { getRange, getLocalDateFromUTC, extractHour, getBeginingOfMonthRange, getChartType };
