import React from "react";
import closeIcon from "../../assets/images/imageIcon/img_close.svg";

const GenerateBillResponseModal = ({ onClose }) => {
  const data = JSON.parse(localStorage.getItem("generateBillResponse"));

  return (
    <div className="fixed inset-0 flex items-center justify-center z-0 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
        <div
          className="mb-2 cursor-pointer justify-end flex items-center"
          onClick={onClose}
        >
          <img className=" mr-1" src={closeIcon} alt="close" />
        </div>
        <div className="mt-4">
          <h2 className="font-bold text-center text-[18px]">
            Generate Bill Information
          </h2>
          {data?.apartmentBillRequests?.map((apartment, id) => {
            return (
              <div className="mt-5" key={id}>
                <p>Unit {apartment.apartmentNumber}</p>
                <div className="flex my-2">
                  <p>Message:</p>
                  <p className="ml-1">{apartment?.message}</p>
                </div>
                <div className="flex mb-2">
                  <p>Status:</p>
                  <p
                    className={`ml-1 ${
                      apartment?.created ? "text-[green]" : "text-[red]"
                    } `}
                  >
                    {apartment?.created ? "Success" : "Failed"}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GenerateBillResponseModal;
