import React, { useState, useEffect } from "react";
import { useSearchParams, useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";

import ElectricityIcon from "../../assets/images/imageIcon/img_ellipse146.png";
import GasIcon from "../../assets/images/imageIcon/img_ellipse146_32x32.png";
import ColdWaterIcon from "../../assets/images/imageIcon/img_ellipse146_1.png";
import HotWaterIcon from "../../assets/images/imageIcon/img_ellipse146_2.png";
import HeatingIcon from "../../assets/images/imageIcon/img_ellipse146_3.png";
import EVChargingIcon from "../../assets/images/imageIcon/img_ellipse146_4.png";

import DashbaordTwoAnalysticcard from "components/DashbaordTwoAnalysticcard";
import { Line, Text, List, Img } from "components";
import IMIDCustomerTwoDashboardcard from "components/IMIDCustomerTwoDashboardcard";
import { getData, getLastMonthUsage } from "services/data/data-api";
import { getChartType, getRange } from "services/data-utils";
import { getEnergyChartData } from "services/chartDataUtil";
import BackLogoIconComponent from "components/BackLogoIcon/BackLogoIconComponent";
import BillingHistory from "components/BillingHistory/BillingHistory";

const emptyData = [];
const TenantChartComponent = () => {
  const user = useSelector((state) => state.auth.userData);

  const { meterNumber } = useParams();
  const [searchParams, setSearchParams] = useSearchParams(); 
  const tenantEmailAddress = searchParams.get('tenantEmailAddress'); 
  const tenantId = searchParams.get('tenantId');
  const accountId = searchParams.get('accountId');
  const params = searchParams.keys();
  const userEmail = user.emailAddress
  const isOwnApartment = tenantEmailAddress.length === 0 || userEmail === tenantEmailAddress
  const isSuperAdmin = user.role === "ROLE_SUPER_ADMIN";

  console.log("====================")
  for (const name of searchParams.keys()) {
    console.log(`${name}: ${searchParams.get(name)}`);
  }
  console.log("====================")
  console.log("tenantEmailAddress: ", tenantEmailAddress);
  console.log("tenantId: ", tenantId);
  console.log(`accountId: ${accountId}`);
  console.log(`isOwnApartment: ${isOwnApartment}`);
  console.log("all parameters: ", params);

  const [data, setdata] = useState([]);
  const [todaysData, setTodaysData] = useState(null);
  const [monthData, setMonthData] = useState(null);
  const [selectedParameter, setselectedParameter] = useState({
    label: "Total Active Energy",
    value: "activeEnergyTotal",
    unit: "kWh",
  });
  const [selectedInterval, setSelectedInterval] = useState({
    label: "Today",
    value: "today",
    scale: "every hour",
    format: "%m/%d %H",
  });

  console.log("Device data: ", data);

  // const imidCustomerTwoDashboardcardPropList = [
  //   { totalcustomers: "Current Month Energy", twenty: "-" },
  //   { totalcustomers: "Current Month Cost", twenty: " -" },
  //   { totalcustomers: "Total Day's Energy", twenty: "" },
  //   { totalcustomers: "Total Day's Cost", twenty: "" },
  //   // { totalcustomers: "Total Transaction (Payment)", twenty: "$0" },
  // ];

  const imidCustomerTwoDashboardcardPropList = [
    {
      totalcustomers: "Total Cumulative Energy (kWh)",
      twenty: todaysData?.totalCumulative,
    },
    {
      totalcustomers: "Current Billing Cycle Energy (kWh)",
      twenty: monthData?.energy,
    },
    {
      totalcustomers: "Current Billing Cycle Cost (C$)",
      twenty: monthData?.amount,
    },
    { totalcustomers: "Total Day's Energy (kWh)", twenty: todaysData?.energy },
    { totalcustomers: "Total Day's Cost (C$)", twenty: todaysData?.amount },
  ];

  const parameters = [
    { label: "Total Active Energy", value: "activeEnergyTotal", unit: "kWh" },
    // { label: "Active Power", value: "powerA", unit: "W" },
    // { label: "Voltage", value: "voltageA", unit: "V" },
    // { label: "Current", value: "currentA", unit: "A" },
  ];

  const intervals = [
    { label: "Today", value: "today", scale: "every 3 hour", format: "%H" },
    {
      label: "Yesterday",
      value: "yesterday",
      scale: "every hour",
      format: "%H",
    },
    {
      label: "This week",
      value: "thisWeek",
      scale: "every day",
      format: "%d %H",
    },
    {
      label: "Last week",
      value: "lastWeek",
      scale: "every day",
      format: "%d %H",
    },
    {
      label: "Last 2 weeks",
      value: "2Weeks",
      scale: "every day",
      format: "%m/%d %H",
    },
    {
      label: "This month",
      value: "thisMonth",
      scale: "every day",
      format: "%m/%d",
    },
    {
      label: "Last month",
      value: "lastMonth",
      scale: "every day",
      format: "%m/%d",
    },
  ];

  useEffect(() => {
    console.log("Reading data for: " + selectedParameter.label);
    console.log("Reading data for: " + selectedInterval.value);
    let [from, to] = getRange(selectedInterval.value);
    console.log("Range: ", selectedInterval, from, to);
    const deviceId = meterNumber;
    const chartType = getChartType(selectedInterval.value);
    console.log("Chart type: ", chartType);
    getData({
      deviceId,
      attribute: selectedParameter.value,
      from, //"2023-03-10T15:00:00",
      to, //"2023-03-14T15:25:00",
      type: selectedInterval.value,
    })
      .then((resp) => {
        console.log("Resp: ", resp);
        const chartData = resp.data;

        //dataPOints: "twelve" or "eight"
        const filteredRecords = getEnergyChartData({
          tariff: 0.18,
          dataPoints: "eight",
          parameter: selectedParameter.value,
          type: selectedInterval.value,
          chartType,
          records: chartData.items,
        });

        console.log("filterd data");
        console.log(filteredRecords);

        if (selectedInterval.value === "today") {
          setTodaysData({
            energy: filteredRecords.energyUsed,
            amount: filteredRecords.amount,
            totalCumulative: filteredRecords.cumulativeEnergy,
          });
          let [from, to] = getRange("lastMonthBilling");
          console.log(`getting last month billing data for device ${deviceId} from ${from} to ${to}`);
          getLastMonthUsage(deviceId,from,to).then((lastMonthsTotalUsage) => {
            console.log("Last month energy: " + lastMonthsTotalUsage);
            const thisMonthUsage =
              lastMonthsTotalUsage &&
              filteredRecords.cumulativeEnergy - lastMonthsTotalUsage;
            const thisMonthBill = thisMonthUsage && thisMonthUsage * 0.17703;
            setMonthData({
              energy: thisMonthUsage.toFixed(2),
              amount: thisMonthBill && thisMonthBill.toFixed(2),
            });
          });
        }

        // setdata(transformData(resp.data.data, selectedParameter.value));
        setdata(filteredRecords);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }, [selectedParameter, selectedInterval]);

  const changeParameter = (data) => {
    console.log("Selected parameter changed: ", data);
    setselectedParameter(data);
    setdata(emptyData);
  };

  const changeInterval = (data) => {
    console.log("Selected interval changed: ", data);
    setSelectedInterval(data);
    setdata(emptyData);
  };

  return (
    <>
      <div className="bg-gray_50 font-lato tenantDashboardContainer grid w-full h-fit mx-[auto] pt-[100px] pb-[50px] ">
        {user.role === "ROLE_ADMIN" ? (
          <div className="mb-20">
            <Link to="/buildings">
              <BackLogoIconComponent />
            </Link>
          </div>
        ) : (
          ""
        )}

        <List
          className="md:flex-col sm:flex-row font-montserrat gap-[40px] grid sm:grid-cols-1 md:grid-cols-3 grid-cols-5 justify-center ml-[auto] mr-[59px] mt-[14px] md:px-[20px] self-stretch w-[100%]"
          orientation="horizontal"
        >
          {imidCustomerTwoDashboardcardPropList.map((props, index) => (
            <React.Fragment key={`IMIDCustomerTwoDashboardcard${index}`}>
              <IMIDCustomerTwoDashboardcard
                className="flex flex-col items-start justify-start self-stretch sm:w-[100%] w-[auto]"
                {...props}
              />
            </React.Fragment>
          ))}
        </List>

        <div className=" flex items-center justify-start mt-[20px] md:px-[20px] w-[100%]">
          <div className="flex flex-col gap-[26px] items-center justify-start w-[100%]">
            {/* Table and Utility Solution div */}
            <div className="flex md:flex-col flex-row gap-[15px] items-center w-[100%]">
              <DashbaordTwoAnalysticcard
                className="h-[500px] relative md:w-[100%] w-[63%] flex-grow"
                // className="h-[500px] relative md:w-[65%] sm:w-[100%]"
                data={data}
                parameter={`${selectedParameter.label} - (${selectedInterval.label})`}
                parameters={parameters}
                selectedParameter={selectedParameter}
                selectedInterval={selectedInterval}
                onChange={changeParameter}
                deviceId={meterNumber}
                intervals={intervals}
                onIntervalChange={changeInterval}
                // scale
              />
              <div
                className="bg-white_A700 h-[500px] border-[1px]  border-bluegray_600 
              border-solid flex flex-col font-montserrat relative gap-[10px] items-start justify-end p-[10px] 
              rounded-[10px] md:w-[35%] sm:w-[100%] chargesBox min-w-[150px]"
              >
                <div className="flex flex-row gap-[23px] items-center justify-start md:ml-[0] ml-[17px] mr-[auto] mt-[8px] self-stretch w-[auto]">
                  <Line className="bg-light_blue_800 h-[40px] w-[3px]" />
                  <div className="flex flex-col gap-[-2px] items-start justify-center self-stretch w-[auto]">
                    <Text
                      className="font-bold text-left text-light_blue_800 w-[auto]"
                      variant="body3"
                    >
                      Charges
                    </Text>
                    <Text
                      className="font-normal not-italic text-gray_600 text-left w-[auto]"
                      variant="body5"
                    >
                      Your active utilities are here.
                    </Text>
                  </div>
                </div>
                <List
                  className="flex-col gap-[20px] grid items-start md:ml-[0] ml-[13px] self-stretch w-[auto]"
                  orientation="vertical"
                >
                  <div className="flex flex-col gap-[12px] items-center justify-start w-[100%]">
                    <div className="flex flex-row items-end justify-between w-[100%]">
                      <div className="flex flex-row font-montserrat gap-[15px] items-center justify-start self-stretch w-[auto]">
                        <Img
                          src={ElectricityIcon}
                          className="h-[32px] md:h-[auto] rounded-[50%] w-[32px]"
                          alt="ellipse146"
                        />
                        <Text
                          className="font-semibold text-bluegray_900 text-justify w-[auto]"
                          variant="body3"
                        >
                          Electricity
                        </Text>
                      </div>
                      <Text
                        className="font-bold font-manrope mb-[4px] mt-[10px] text-bluegray_900 text-justify w-[auto]"
                        variant="body4"
                      >
                        C$0.18
                      </Text>
                    </div>
                    <Line className="bg-blue_100 h-[1px] w-[100%]" />
                  </div>
                  <div className="flex flex-col gap-[12px] items-center justify-start w-[100%]">
                    <div className="flex flex-row items-end justify-between w-[100%]">
                      <div className="flex flex-row font-montserrat gap-[15px] items-center justify-start self-stretch w-[auto]">
                        <Img
                          src={GasIcon}
                          className="h-[32px] md:h-[auto] rounded-[50%] w-[32px]"
                          alt="ellipse146"
                        />
                        <Text
                          className="font-semibold text-bluegray_900 text-justify w-[auto]"
                          variant="body3"
                        >
                          Gas
                        </Text>
                      </div>
                      <Text
                        className="font-bold font-manrope mb-[4px] mt-[10px] text-bluegray_900 text-justify w-[auto]"
                        variant="body4"
                      >
                        $0
                      </Text>
                    </div>
                    <Line className="bg-blue_100 h-[1px] w-[100%]" />
                  </div>
                  <div className="flex flex-col gap-[12px] items-center justify-start w-[100%]">
                    <div className="flex flex-row items-end justify-between w-[100%]">
                      <div className="flex flex-row font-montserrat gap-[15px] items-center justify-start self-stretch w-[auto]">
                        <Img
                          src={ColdWaterIcon}
                          className="h-[32px] md:h-[auto] rounded-[50%] w-[32px]"
                          alt="ellipse146"
                        />
                        <Text
                          className="font-semibold text-bluegray_900 text-justify w-[auto]"
                          variant="body3"
                        >
                          Cold Water
                        </Text>
                      </div>
                      <Text
                        className="font-bold font-manrope mb-[4px] mt-[10px] text-bluegray_900 text-justify w-[auto]"
                        variant="body4"
                      >
                        $0
                      </Text>
                    </div>
                    <Line className="bg-blue_100 h-[1px] w-[100%]" />
                  </div>
                  <div className="flex flex-col gap-[12px] items-center justify-start w-[100%]">
                    <div className="flex flex-row items-end justify-between w-[100%]">
                      <div className="flex flex-row font-montserrat gap-[15px] items-center justify-start self-stretch w-[auto]">
                        <Img
                          src={HotWaterIcon}
                          className="h-[32px] md:h-[auto] rounded-[50%] w-[32px]"
                          alt="ellipse146"
                        />
                        <Text
                          className="font-semibold text-bluegray_900 text-justify w-[auto]"
                          variant="body3"
                        >
                          Hot Water
                        </Text>
                      </div>
                      <Text
                        className="font-bold font-manrope mb-[4px] mt-[10px] text-bluegray_900 text-justify w-[auto]"
                        variant="body4"
                      >
                        $0
                      </Text>
                    </div>
                    <Line className="bg-blue_100 h-[1px] w-[100%]" />
                  </div>
                  <div className="flex flex-col gap-[12px] items-center justify-start w-[100%]">
                    <div className="flex flex-row items-end justify-between w-[100%]">
                      <div className="flex flex-row font-montserrat gap-[15px] items-center justify-start self-stretch w-[auto]">
                        <Img
                          src={HeatingIcon}
                          className="h-[32px] md:h-[auto] rounded-[50%] w-[32px]"
                          alt="ellipse146"
                        />
                        <Text
                          className="font-semibold text-bluegray_900 text-justify w-[auto]"
                          variant="body3"
                        >
                          Heating
                        </Text>
                      </div>
                      <Text
                        className="font-bold font-manrope mb-[4px] mt-[10px] text-bluegray_900 text-justify w-[auto]"
                        variant="body4"
                      >
                        $0
                      </Text>
                    </div>
                    <Line className="bg-blue_100 h-[1px] w-[100%]" />
                  </div>
                  <div className="flex flex-col gap-[12px] items-center justify-start w-[100%]">
                    <div className="flex flex-row items-end justify-between w-[100%]">
                      <div className="flex flex-row font-montserrat gap-[15px] items-center justify-start self-stretch w-[auto]">
                        <Img
                          src={EVChargingIcon}
                          className="h-[32px] md:h-[auto] rounded-[50%] w-[32px]"
                          alt="ellipse146"
                        />
                        <Text
                          className="font-semibold text-bluegray_900 text-justify w-[auto]"
                          variant="body3"
                        >
                          EV Charging
                        </Text>
                      </div>
                      <Text
                        className="font-bold font-manrope mb-[4px] mt-[10px] text-bluegray_900 text-justify w-[auto]"
                        variant="body4"
                      >
                        $0
                      </Text>
                    </div>
                    <Line className="bg-blue_100 h-[1px] w-[100%]" />
                  </div>
                </List>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(isOwnApartment || isSuperAdmin) && <BillingHistory tenantId={tenantId} accountId={accountId} />}
    </>
  );
};

export default TenantChartComponent;
