import React, { useState, useEffect } from "react";
import { Button, Input } from "components";
import "./CustomerFormWrapper.css";

const CreateCustomerForm = ({makeTabActive}) => {

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [customerType, setCustomerType] = useState("ENERGY_COMPANY");
  const [error, setError] = useState("");

  const [isAddBuildingChecked, setIsAddBbuildingChecked] = useState(false)

  const handleCheckboxChange = () => {
    setIsAddBbuildingChecked(!isAddBuildingChecked);
    JSON.stringify(localStorage.setItem('isAddBuildingChecked',!isAddBuildingChecked))
  };

  const customerInfo = {
    name, phoneNumber, emailAddress, address, city, province, postalCode, country, customerType
  }

  const validatePhoneNumber = (number) => {
    // Regular expression for validating international phone numbers (E.164 format)
    const phoneRegEx = /^\+?[1-9]\d{1,14}$/;  // Matches numbers with a + and up to 15 digits

    if (phoneRegEx.test(number)) {
      setError(''); // Clear error if valid
      return true;
    } else {
      setError('Please enter a valid international phone number (e.g., +1234567890).');
      return false;
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    validatePhoneNumber(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
      if (name === '' || phoneNumber === '' || emailAddress === '' || city === '' || province === '' || postalCode === '' || country === '' || address === '') {
        setError('Please fill all inputs correctly')
        return
      }
      else{
        // let customerData = JSON.stringify(customerInfo)
        // console.log('Storing to localStorage:', customerData); // Debugging
        localStorage.setItem('customerInfo',JSON.stringify(customerInfo))
        makeTabActive(2)

      }
  };

  // check if the user has already typed into the inputs before moving to the next page
  useEffect(() => {
    const customer = JSON.parse(localStorage.getItem('customerInfo'))
    // console.log('Loaded from localStorage:', customer); 
    if (customer){
      setName(customer?.name);
      setPhoneNumber(customer?.phoneNumber);
      setEmailAddress(customer?.emailAddress);
      setCity(customer?.city);
      setProvince(customer?.province);
      setPostalCode(customer?.postalCode);
      setCountry(customer?.country);
      setCustomerType(customer?.customerType);
      setAddress(customer?.address);
    }
  }, [])

  useEffect(() => {
    const isChecked = JSON.parse(localStorage.getItem('isAddBuildingChecked'))
    if (isChecked){
      setIsAddBbuildingChecked(true)
    }
  }, [])

  // console.log('Stored Data:', JSON.parse(localStorage.getItem('customerInfo')));


  return (
    <>
      <form 
      onSubmit={handleSubmit}
      >
        <div className="customer-form-wrapper">
          <div>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="user-name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              labelText="Company/Customer Name"
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-blue-500 peer-focus:dark:text-blue-500"
              name="emailaddress"
              type = 'email'
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              labelText="Email Address"
              required={true}
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="phonenumber"
              value={phoneNumber}
              maxLength={11}
              onChange={handleChange}
              type="number"
              labelText="Phone Number"
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Address"
              value={address}
              type = 'text'
              onChange={(e) => setAddress(e.target.value)}
              labelText="Address"
            ></Input>

            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              labelText="City"
            ></Input>


          </div>
          <div>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="province"
              value={province}
              onChange={(e) => setProvince(e.target.value)}
              labelText="Province"
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Zip Code"
              value={postalCode}
              type="text"
              onChange={(e) => setPostalCode(e.target.value)}
              labelText="Zip Code"
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              labelText="Country"
            ></Input>
            
            <div className=" w-full " >
              <select value={customerType} onChange={(e) => setCustomerType(e.target.value)} className='w-full text-sm bg-gray_100 border-[1px] text-[16px] text-gray_600 font-semibold outline-none border-light_blue_800 font-montserrat h-[66px] md:h-[auto] mt-[25px] rounded-[10px] py-[11px]' >
                <option value='ENERGY_COMPANY' >Energy Company</option>
                <option value='PROPERTY_MANAGER' >Property Manager</option>
                <option value='HOME_OWNER' >Home Owner</option>
              </select>
            </div>
          </div>
          <p className='text-[red] text-[12px]' >{error}</p>
        </div>
        <div className = 'flex mb-4' >
          <input onChange={handleCheckboxChange} checked = {isAddBuildingChecked} id = 'check' type="checkbox" name="" />
          <label htmlFor = 'check' className="ml-2" >Add building for tenant occupancy</label>
        </div>
        <Button type='submit' className="bg-light_blue_800 cursor-pointer font-extrabold h-[64px] mb-[20px] px-[7px] sm:px-[15px] sm:py-[5px] py-[10px] rounded-[10px] text-[14px] text-center text-white_A700 w-[150px]">
            Next
        </Button>
       {/* <Button type='submit' className="bg-light_blue_800 cursor-pointer font-extrabold h-[64px] mb-[20px] px-[7px] sm:px-[15px] sm:py-[5px] py-[10px] rounded-[10px] text-[14px] text-center text-white_A700 w-[150px]">
          {loading ? (
            <ClipLoader size={20} color={"#ffffff"} />
          ) : (
            "Add Customer"
          )}
        </Button>*/}
      </form>
    </>
  );
};

export default CreateCustomerForm;
